import { EnglishLanguage } from '@onix/common/src/constants/language.constants';
import { InitOptions } from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { da, de, en, es, et, fi, i18n, lt, lv, nl, no, pl, pt, sv } from '../constants/languages.constants';
import { SettingKeyConstants } from '../constants/setting-keys.constants';
import { LocalStorageService } from '../services/local-storage.service';

// Make the translation function to not return null when key is not found.
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

/**
 * Convert language captions format with namespace to the normal key-value (like in new OnixWork"s Equipment Modern).
 */
const flattenLanguageJsonObject = (captionObj: Record<string, Record<string, string>>): Record<string, string> => {
  const keyValueTupleArray = Object.entries(captionObj)
    .map((namespaceObject) =>
      Object.entries(namespaceObject[1]).map((keyValue) => [`${namespaceObject[0]}.${keyValue[0]}`, keyValue[1]] as [string, string])
    )
    .flat();

  return Object.fromEntries(new Map(keyValueTupleArray));
};

export default async function configureTranslation() {
  return i18n.use(detector).use(initReactI18next).init(getI18NLanguageOptions());
}

const getI18NLanguageOptions = () => {
  if (LocalStorageService.shouldLog()) {
    (window as any).i18n = i18n;
  }

  const resources = {
    no: { translation: flattenLanguageJsonObject(no) },
    en: { translation: flattenLanguageJsonObject(en) },
    sv: { translation: flattenLanguageJsonObject(sv) },
    da: { translation: flattenLanguageJsonObject(da) },
    nl: { translation: flattenLanguageJsonObject(nl) },
    de: { translation: flattenLanguageJsonObject(de) },
    es: { translation: flattenLanguageJsonObject(es) },
    fi: { translation: flattenLanguageJsonObject(fi) },
    pt: { translation: flattenLanguageJsonObject(pt) },
    et: { translation: flattenLanguageJsonObject(et) },
    lv: { translation: flattenLanguageJsonObject(lv) },
    lt: { translation: flattenLanguageJsonObject(lt) },
    pl: { translation: flattenLanguageJsonObject(pl) },
  };

  return {
    resources,
    fallbackLng: EnglishLanguage.code,
    cleanCode: false,
    load: 'languageOnly',
    detection: {
      order: ['localStorage'],

      lookupLocalStorage: LocalStorageService.getLocalStorageKey(SettingKeyConstants.Language),
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      excludeCacheFor: ['cimode', 'localStorage'],
      htmlTag: document.documentElement,
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
    react: { useSuspense: false },
    parseMissingKeyHandler: (key: string, _defaultValue?: string) => '#' + key,
  } as InitOptions;
};

/**
 * Format a translated caption by a key with formatObject.
 */
export const f = (t: (key: string) => string) => (key: string, formatObject: Record<string, any> | undefined) => {
  let translatedCaption = t(key);

  if (formatObject === undefined) {
    return translatedCaption;
  }

  for (const [key, value] of Object.entries(formatObject)) {
    translatedCaption = translatedCaption.replaceAll(`{${key}}`, value);
  }

  return translatedCaption;
};

export const translateUndefined = (t: (key: string) => string, key: string | undefined) => (key === undefined ? '' : t(key));
