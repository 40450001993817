import { LanguageHelper, SupportedLanguageConstants } from '@onix/common';
import i18n from 'i18next';
import * as da from '../assets/i18n/da.json';
import * as de from '../assets/i18n/de.json';
import * as en from '../assets/i18n/en.json';
import * as es from '../assets/i18n/es.json';
import * as et from '../assets/i18n/et.json';
import * as fi from '../assets/i18n/fi.json';
import * as lt from '../assets/i18n/lt.json';
import * as lv from '../assets/i18n/lv.json';
import * as nl from '../assets/i18n/nl.json';
import * as no from '../assets/i18n/no.json';
import * as pl from '../assets/i18n/pl.json';
import * as pt from '../assets/i18n/pt.json';
import * as sv from '../assets/i18n/sv.json';

const LanguageIdToLanguageShort = Object.fromEntries(SupportedLanguageConstants.map((a) => [a.id, a.code]));

export const LanguageName = SupportedLanguageConstants.map((m) => {
  return {
    id: m.id,
    description: m.name,
  };
});

const parseLanguageIdToLanguageShort = LanguageHelper.getLanguageCode;

export { da, de, en, es, et, fi, i18n, LanguageIdToLanguageShort, lt, lv, nl, no, parseLanguageIdToLanguageShort, pl, pt, sv };
