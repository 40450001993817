import { deleteAsync, downloadAsync, getAsync, postAsync, putAsync } from './http-client.service';
import getDataUrlImageCropped, { compressFile, compressFileToBase64, getDataUrlFromFile, isImageFile } from './image.service';
const HttpClient = {
  getAsync,
  postAsync,
  putAsync,
  deleteAsync,
  downloadAsync,
};

const ImageService = {
  isImageFile,
  compressFileToBase64,
  getDataUrlFromFile,
  compressFile,
  getDataUrlImageCropped,
};
export { HttpClient, ImageService };
