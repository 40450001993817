import { Shimmer as FluentShimmer, IShimmerProps, ThemeProvider, mergeStyles } from '@fluentui/react';

type Props = IShimmerProps & {
  lines?: number;
  equalLine?: boolean;
};

const wrapperClass = mergeStyles({
  padding: 2,
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '10px 0',
    },
  },
});

const Shimmer = ({ lines = 1, equalLine = false }: Props) => {
  const lineWidths = ['100%', '85%', '50%', '70%', '60%', '40%'];

  return (
    <ThemeProvider className={wrapperClass}>
      {Array.from(Array(lines).keys()).map((_, index) => (
        <FluentShimmer key={index} width={equalLine ? '100%' : lineWidths[index % lineWidths.length]} />
      ))}
    </ThemeProvider>
  );
};

export default Shimmer;
