export const color = {
  $primaryColor: '#8D398E',
  $textColor: '#FAFAFA',
  $white: '#FFFFFF',
  $black: '#000000',
  $red: '#A4262C',

  $grey10: '#FAF9F8',
  $grey30: '#EDEBE9',
  $grey50: '#D2D0CE',
  $grey60: '#C8C6C4',
  $grey90: '#A19F9D',
  $grey130: '#605E5C',
  $grey110: '#8A8886',
  $grey190: '#201F1E',
  $grey160: '#323130',

  $borderLight: '#C1C7D0',
  $linkColor: 'rgb(12, 100, 192)',

  $greenColor: '#107C10'
}

export const height = {
  CommandBar: 32,
}