import { LanguageEnum } from '../constants';
import {
  DanishLanguage,
  DutchLanguage,
  EnglishLanguage,
  EstonianLanguage,
  FinnishLanguage,
  GermanLanguage,
  LatvianLanguage,
  LithuanianLanguage,
  NorwegianLanguage,
  PolishLanguage,
  PortugueseLanguage,
  SpanishLanguage,
  SupportedLanguages,
  SwedishLanguage,
} from '../constants/language.constants';

function getLanguageEnum(language: string): LanguageEnum {
  let languageId = null;

  switch (language) {
    case NorwegianLanguage.code: {
      languageId = LanguageEnum.Norwegian;
      break;
    }
    case EnglishLanguage.code: {
      languageId = LanguageEnum.English;
      break;
    }
    case SwedishLanguage.code: {
      languageId = LanguageEnum.Swedish;
      break;
    }
    case DanishLanguage.code: {
      languageId = LanguageEnum.Danish;
      break;
    }
    case DutchLanguage.code: {
      languageId = LanguageEnum.Dutch;
      break;
    }
    case GermanLanguage.code: {
      languageId = LanguageEnum.German;
      break;
    }
    case SpanishLanguage.code: {
      languageId = LanguageEnum.Spanish;
      break;
    }
    case FinnishLanguage.code: {
      languageId = LanguageEnum.Finnish;
      break;
    }
    case PortugueseLanguage.code: {
      languageId = LanguageEnum.Portuguese;
      break;
    }
    case EstonianLanguage.code: {
      languageId = LanguageEnum.Estonian;
      break;
    }
    case LatvianLanguage.code: {
      languageId = LanguageEnum.Latvian;
      break;
    }
    case LithuanianLanguage.code: {
      languageId = LanguageEnum.Lithuanian;
      break;
    }
    case PolishLanguage.code: {
      languageId = LanguageEnum.Polish;
      break;
    }
    default: {
      languageId = LanguageEnum.English;
    }
  }

  return languageId;
}

function getLanguageCode(languageId: LanguageEnum): string {
  const language = SupportedLanguages[languageId]?.code ?? EnglishLanguage.code;
  return language;
}

export const LanguageHelper = {
  getLanguageEnum,
  getLanguageCode,
};
